<template>
  <div class="single-traffic-flow manage-scroll">
    <div class="common-module flow-module-one relative">
      <div class="module-content table-wrapper">
        <div style="width: 100%; margin-left: -10px">
          <c-search
              v-model="form"
              labelWidth="10px"
              :searchItems="searchItems"
              @search="handleSearch"
              @reset="handleReset"
              @export="handleExport"
          >
          </c-search>
        </div>
        <div class="table-wrapper" style="height: calc(100% - 25px)">
          <c-table
              :data="pageData"
              :tableColumns="tableColumns"
              v-loading="pageLoading"
              :stripe="true"
              :pagination="form"
              @change="pageGet"
          >
            <template v-slot:isOverload="{ row }">
              <span>{{row.isOverload === 0 ? '否' : '是'}}</span>
            </template>
            <template v-slot:custom="{ row }">
              <span class="opt-detail" @click="$router.push({path:'/single/traffic/flow/detail/' + row.id,query:{type:0}})">详情</span>
            </template>
          </c-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Configs from "./config/index";
  import common from "@/assets/js/common";
  export default {
    name: "index",
    data(){
      let { searchItems,tableColumns } = Configs(this);
      return{
        searchItems,
        tableColumns,
        form: {
          pageSize: 13,
          pageNum: 1,
          currentPage: 1,
          total: 0,
          carNum: "",
          date: [],
          isOverload:'',
          laneNum:'',
          grossWeight:'',
          axleNum:''
        },
        pageLoading: false,
        pageData: [],
      }
    },
    created() {
      this.carLaneNum()
      this.form.date[0] = this.$moment(new Date()).add('year',0).format("YYYY-MM-DD")
      this.form.date[1] = this.$moment(new Date()).add('year',0).format("YYYY-MM-DD")
      this.pageGet()
    },
    methods:{
      carLaneNum(){
        this.$http.get('/car/carLaneNum').then(res => {
          if(res.success){
            this.searchItems[2].options = res.data
          }
        })
      },
      pageGet() {
        this.pageLoading = true
        let params = {
          startTime:this.form.date[0],
          endTime:this.form.date[1],
          carNum:this.form.carNum,
          isOverload:this.form.isOverload,
          laneNum:this.form.laneNum,
          grossWeight:this.form.grossWeight,
          axleNum:this.form.axleNum,
          pageNum:this.form.pageNum,
          pageSize:this.form.pageSize
        }
        if (this.form.date[0]){
          params.startTime = this.form.date[0] + ' 00:00:00'
        }
        if (this.form.date[1]){
          params.endTime = this.form.date[1] + ' 23:59:59'
        }
        this.$http.post("/car/carList",params).then(res=>{
          if(res.success){
            if (res.data){
              this.pageData = res.data.list
              this.form.total = res.data.total
            }
          } else {
            this.$message.error(res.msg)
          }
          this.pageLoading = false
        })
      },
      handleReset() {
        this.form.card = ''
        this.form.date[0] = this.$moment(new Date()).add('year',0).format("YYYY-MM-DD")
        this.form.date[1] = this.$moment(new Date()).add('year',0).format("YYYY-MM-DD")
        this.form.isOverload = ''
        this.form.laneNum = ''
        this.form.grossWeight = ''
        this.form.axleNum = ''
        this.pageGet()
      },
      handleSearch() {
        this.pageGet()
      },
      handleExport(){
        let params = {
          startTime:this.form.date[0] + ' 00:00:00',
          endTime:this.form.date[1] + ' 23:59:59',
          carNum:this.form.carNum,
          isOverload:this.form.isOverload,
          laneNum:this.form.laneNum,
          grossWeight:this.form.grossWeight,
          axleNum:this.form.axleNum,
        }
        this.$http.post('/car/carData/export', params,{contentType:"application/x-www-form-urlencoded",
          responseType: "blob"}
        ).then(res => {
          common.blobDownLoad(res)
        });
      }
    }
  }
</script>

<style scoped>
  .form-container .search-container{
    width: calc(100% - 160px);
  }
</style>