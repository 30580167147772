export default function Configs() {
  const searchItems = [
    {
      label: "",
      span: 6,
      placeholder: "",
      model: "date",
      type: "daterange",
      labelWidth:0,
    },
    {
      label: "",
      span: 3,
      placeholder: "是否超限",
      model: "isOverload",
      type: "select",
      options:[{value:0,label:'未超限'},{value:1,label:'超限'}],
      labelWidth:0,
    },
    {
      label: "",
      span: 4,
      placeholder: "车道",
      model: "laneNum",
      type: "select",
      options:[],
      labelWidth:0,
    },
    {
      label: "",
      span: 4,
      placeholder: "车重(大于)",
      type: "input",
      model: "grossWeight",
    },
    {
      label: "",
      span: 3,
      placeholder: "车轴",
      model: "axleNum",
      type: "select",
      options:[{value:2,label:'2车轴'},{value:3,label:'3车轴'},{value:4,label:'4车轴'},{value:5,label:'5车轴'},{value:6,label:'6车轴及以上'}],
      labelWidth:0,
    },
    {
      label: "",
      span: 4,
      placeholder: "车牌号",
      type: "input",
      model: "carNum",
    },
  ]
  const tableColumns = [
    {
      label: "检测时间",
      prop: "checkTime",
      align: "center",
      show: true
    },
    {
      label: "车道",
      prop: "laneNum",
      align: "center",
      show: true
    },
    {
      label: "车牌",
      prop: "carNum",
      align: "center",
      show: true
    },
    {
      label: "轴数",
      prop: "axleNum",
      align: "center",
      show: true
    },
    {
      label: "车重(kg)",
      prop: "grossWeight",
      align: "center",
      show: true
    },
    {
      label: "是否超限",
      name:'isOverload',
      align: "center",
      show: true
    },
    {
      label: "车速(km/h)",
      prop: "speed",
      align: "center",
      show: true
    },
    {
      label: "操作",
      name:'custom',
      align: "center",
      show: true
    },
  ];
  return {
    searchItems,
    tableColumns
  };
}
